import React from 'react'
import { graphql } from 'gatsby'
import CompareTable from '../molecules/go-cloud/compare-table'
import RenderCard from '../molecules/go-cloud/recharge-card'

import { Wrapper, SEO } from '@renderbus/common/components'
import { baseLink, UserAuthorizationService } from '@renderbus/common/service'

import Layout from '../molecules/layout'
import {
  BannerContainer,
  GoCloudBackground,
  IntroWrapper,
  IntroText,
  GoCloudTitle,
  GoCloudSubTitle,
  SubTitle,
  GPUCentent,
  GPUDesc,
  GPUWrapper,
  GPUDetailBtn,
  DateTitle,
  Title,
  QingyunColumn,
  QingyunRow,
  GoCloundWarnTipDialog,
  GoCloundWarnBtn,
  GoCloundWarnTitle,
  GoCloundWarnHeader,
  GoCloundWarnTipDialogBody,
  GoCloundWarnTipMask,
  GoCloundWarnBtnGroup,
  Tip,
} from './go-cloud.atom'
import { EventBus } from '@renderbus/common/service'

class GoCloud extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      bannerBg: null,
      activatingQingyun: true,
      isOpenPictureRechargeTip: false,
      isClickRerchageBtn: false,
    }
    this.handlerResize = this.handlerResize.bind(this)
    this.handleGoCloundPageChange = this.handleGoCloundPageChange.bind(this)
  }
  handlerResize() {
    if (typeof window !== 'undefined' && window.innerWidth > 600) {
      this.setState({ bannerBg: this.props.data.banner.childImageSharp.fluid })
    } else {
      this.setState({ bannerBg: this.props.data.bannerPhone.childImageSharp.fluid })
    }
  }
  handleGoCloundPageChange() {
    if (document.hidden) return
    if (
      this.state.isClickRerchageBtn &&
      UserAuthorizationService.getoginUserType() === 'true' &&
      !this.state.isOpenPictureRechargeTip
    ) {
      this.handleOperateRecharge()
    }
  }
  handleOperateRecharge() {
    if (!UserAuthorizationService.isLogined()) {
      this.setState({ isClickRerchageBtn: true })
    }
    if (
      UserAuthorizationService.getoginUserType() === 'true' &&
      UserAuthorizationService.isLogined()
    ) {
      this.setState({ isOpenPictureRechargeTip: true })
      const htmlElement = document.documentElement
      htmlElement.style.overflow = 'hidden'
      htmlElement.style['-webkit-overflow-scrolling'] = 'touch'
      htmlElement.style.width = '100%'
      return
    }
    window.open(`${baseLink()}/center/user/topUp?activeName=cpu-qingyun`, '_blank')
  }
  handleCloseDialog() {
    const htmlElement = document.documentElement
    const viewportWidth = document.body.clientWidth
    htmlElement.style.overflowY = 'auto'
    htmlElement.style.overflowX = 'auto'
    htmlElement.style['-webkit-overflow-scrolling'] = 'unset'
    if (viewportWidth < 780) {
      htmlElement.style.overflowX = 'hidden'
    }
    this.setState({ isOpenPictureRechargeTip: false, isClickRerchageBtn: false })
  }
  componentDidMount() {
    if (typeof window === 'undefined') return
    this.handlerResize()
    window.addEventListener('resize', this.handlerResize)
    EventBus.addListener('loginStatusUpdate', this.handleGoCloundPageChange)
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.resizeHandler)
      EventBus.removeListener('loginStatusUpdate', this.handleGoCloundPageChange)
    }
  }
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props

    const { activatingQingyun, isOpenPictureRechargeTip } = this.state
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='CPU青云平台,CPU云渲染充值优惠活动平台-Renderbus云渲染农场'
          keywords='CPU青云平台,CPU渲染,高性价比云渲染平台'
          description='青云平台是瑞云打造的高性价比CPU云渲染充值优惠平台,有着Renderbus平台的核心功能,CPU青云平台经济实惠,轻量高效,为中小型工作室及中小型动画创意项目提供一个全新的“简洁版”云渲染平台.'
          sharePostSlug='go-cloud.html'
        />
        <BannerContainer>
          <GPUCentent>
            <GPUDesc>影视动画用户专享</GPUDesc>
            <Title>
              <span>CPU青云平台</span>超值上线
            </Title>
            <SubTitle>轻松享受低价而优质的服务</SubTitle>
            <GPUDetailBtn onClick={() => this.handleOperateRecharge()}>{'立即充值'}</GPUDetailBtn>
            <Tip>*该平台暂不支持V-Ray 7.0 for 3ds Max、Cinema 4D 2024~2025</Tip>
            <DateTitle>限时开放</DateTitle>
          </GPUCentent>
        </BannerContainer>
        <GoCloudBackground>
          <IntroWrapper>
            <IntroText>
              青云平台是Renderbus瑞云渲染专为中小型动画工作室量身打造的“轻量实惠版”CPU云渲染平台，影视番剧、动画剧集等项目均可使用，青云用户可以用更优惠的价格享受专业的云渲染服务。青云平台继承了瑞云渲染的优秀功能，如支持主流三维软件与插件、一键拖拽、快速渲染等，集经济实惠、轻量高效，简单易用于一体。
            </IntroText>
          </IntroWrapper>
          <GPUWrapper>
            <GoCloudTitle>CPU青云平台</GoCloudTitle>
            <GoCloudSubTitle>以CPU青云券计费，统一按照0.2券/核时的价格进行计费</GoCloudSubTitle>
            <QingyunRow spacing={3}>
              <QingyunColumn xs='12' lg='4.8'>
                <RenderCard
                  disabled={!activatingQingyun}
                  type='CPU'
                  recharge='2000-4999'
                  coupon='7111-17774'
                  classProp='first'
                  unit='0.9'
                  getRechargeEvent={() => this.handleOperateRecharge()}
                />
              </QingyunColumn>
              <QingyunColumn xs='12' lg='4.8'>
                <RenderCard
                  type='CPU'
                  recharge='5000'
                  coupon='20000 '
                  unit='0.8'
                  getRechargeEvent={() => this.handleOperateRecharge()}
                />
              </QingyunColumn>
            </QingyunRow>
            <QingyunRow spacing={3} className='second'>
              <QingyunColumn xs='12' lg='4.8'>
                <RenderCard
                  type='CPU'
                  recharge='10000'
                  coupon='45715'
                  unit='0.7'
                  getRechargeEvent={() => this.handleOperateRecharge()}
                />
              </QingyunColumn>
              <QingyunColumn xs='12' lg='4.8'>
                <RenderCard
                  disabled={!activatingQingyun}
                  type='CPU'
                  recharge='20000'
                  coupon='106666'
                  unit='0.6'
                  getRechargeEvent={() => this.handleOperateRecharge()}
                />
              </QingyunColumn>
            </QingyunRow>
            <QingyunRow spacing={3} className='second'>
              <QingyunColumn xs='12' lg='4.8'>
                <RenderCard
                  disabled={!activatingQingyun}
                  type='CPU'
                  recharge='30000'
                  coupon='192000'
                  unit='0.5'
                  getRechargeEvent={() => this.handleOperateRecharge()}
                />
              </QingyunColumn>
              <QingyunColumn xs='12' lg='4.8'>
                <RenderCard coupon='更多需求' classProp='last' />
              </QingyunColumn>
            </QingyunRow>
          </GPUWrapper>
          <Wrapper padding={'0 1rem 3rem 1rem'}>
            <GoCloudTitle>平台对比</GoCloudTitle>
            <CompareTable />
          </Wrapper>
        </GoCloudBackground>
        {isOpenPictureRechargeTip && (
          <GoCloundWarnTipMask>
            <GoCloundWarnTipDialog>
              <GoCloundWarnHeader onClick={() => this.handleCloseDialog()} />
              <GoCloundWarnTipDialogBody>
                <GoCloundWarnTitle>温馨提示</GoCloundWarnTitle>
                <p className='goclound-content'>青云平台仅限影视动画用户使用，您可以</p>
                <GoCloundWarnBtnGroup>
                  <GoCloundWarnBtn
                    as='a'
                    href={`${baseLink()}/sso/register`}
                    onClick={() => this.handleCloseDialog()}
                  >
                    {'注册动画账号'}
                  </GoCloundWarnBtn>
                  <GoCloundWarnBtn
                    as='a'
                    href={`${baseLink()}/center/user/topUp?activeName=cpu-qingyun`}
                    onClick={() => this.handleCloseDialog()}
                  >
                    {'继续效果图充值'}
                  </GoCloundWarnBtn>
                </GoCloundWarnBtnGroup>
              </GoCloundWarnTipDialogBody>
            </GoCloundWarnTipDialog>
          </GoCloundWarnTipMask>
        )}
      </Layout>
    )
  }
}

export default GoCloud

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/go-cloud/banner.jpg/" }) {
      ...fluidImage
    }
    bannerPhone: file(relativePath: { regex: "/go-cloud/phone-banner.png/" }) {
      ...fluidImage
    }
  }
`
