import React from 'react'

import { CompareTableContainer } from './atom'

import compareTableDatas from './compare-table-data'

class CompareTable extends React.PureComponent {
  render() {
    return (
      <CompareTableContainer>
        <thead>
          <tr>
            <th>功能</th>
            <th>CPU青云平台</th>
            <th>标准平台</th>
          </tr>
        </thead>
        <tbody>
          {compareTableDatas.map((data, index) => {
            return (
              <tr key={index}>
                <td>{data.name}</td>
                <td>{data.CPUQingyun}</td>
                <td>{data.standard}</td>
              </tr>
            )
          })}
        </tbody>
      </CompareTableContainer>
    )
  }
}

export default CompareTable
