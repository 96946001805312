class compareTableDataForm {
  constructor(name = '', CPUQingyun = '', GPUQingyun = '', standard = '') {
    this.name = name
    this.CPUQingyun = CPUQingyun
    this.GPUQingyun = GPUQingyun
    this.standard = standard
  }
}
const compareTableDatas = [
  new compareTableDataForm('所有三维软件', '支持', '不支持', '支持'),
  new compareTableDataForm('主流插件', '兼容', '支持', '兼容'),
  new compareTableDataForm('主流渲染器', '兼容', '支持OC4.0以下版本', '兼容'),
  new compareTableDataForm('7X24小时客服服务', '支持', '\\', '支持'),
  new compareTableDataForm(
    '7X16小时技术服务',
    '支持（08:00-24:00）',
    '兼容',
    '支持（00:00-24:00）',
  ),
  new compareTableDataForm('提交方式', '客户端/网页端', '兼容', '客户端/网页端'),
  new compareTableDataForm('一键式拖拽客户端', '支持', '支持', '支持'),
  new compareTableDataForm('镭速传输引擎', '支持', '支持', '支持'),
  new compareTableDataForm('Aspera传输引擎', '支持', '支持', '支持'),
  new compareTableDataForm('子账户功能', '支持', '支持', '支持'),
  new compareTableDataForm('超时提醒功能', '支持', '支持', '支持'),
  new compareTableDataForm('高速集中式SSD存储', '支持', '支持', '支持'),
  new compareTableDataForm('16核以上渲染节点配置', '不支持', '支持', '支持'),
  new compareTableDataForm('节点机分配', '即时分配', '支持', '即时分配'),
  new compareTableDataForm('文件保存时间', '14天', '\\', '20天'),
  new compareTableDataForm('渲染价格', '充值赠送，性价比高', '\\', 'CPU按核计费'),
  new compareTableDataForm('单任务最多分配节点机数量', '不限制', 'P102X2', '不限制'),
  new compareTableDataForm('专属TD和PC', '\\', '14天', '支持'),
  new compareTableDataForm('定制客户端和插件', '\\', '\\', '支持'),
  new compareTableDataForm('上门取送件', '\\', '100', '支持'),
  new compareTableDataForm('专线传输', '\\', '\\', '支持'),
  new compareTableDataForm('API/SDK', '\\', '\\', '支持'),
]
export default compareTableDatas
