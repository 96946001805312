import { Flex } from '@renderbus/common/components'
import { color, shadows, typography } from '@renderbus/common/theme'
import styled from 'styled-components'
import CardBg from '../../../images/go-cloud/card-bg.png'
import CardBgHover from '../../../images/go-cloud/card-bg-hover.png'
import SubscriptImg from '../../../images/go-cloud/sub-script.png'
import Media from '@renderbus/common/theme/media'

export const Card = styled.div`
  padding: 40px 106px 40px 70px;
  background-image: url(${CardBg});
  background-repeat: no-repeat;
  transition: 0s;
  height: 236px;
  width: 606px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  &.first {
    padding-left: 50px;
  }
  &.last {
    padding: 46px 106px 40px 120px;
  }
  :hover {
    background-position: -10px -10px;
    background-image: url(${CardBgHover});
    a {
      background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
      box-shadow: 0px 4px 7px 1px rgba(64, 194, 93, 0.3);
      color: white;
      border: unset;
      font-weight: 400;
    }
  }
  a {
    background: unset;
    border: 1px solid #13cb75;
    color: #13cb75;
    font-weight: 400;
  }
  .more-button {
    margin-top: 34px;
    width: 140px;
  }
  .recharge-button {
    width: 140px;
    margin-top: 15px;
  }
  ${Media.lessThan(Media.small)} {
    height: 31.46vw;
    width: 100%;
    padding: 0;
    background-size: 100%;
    padding: 8vw 9.06vw 8vw 12vw;
    gap: 0px;
    align-items: center;
    &.first {
      padding-left: 5.33vw;
    }
    &.last {
      padding: 30px 34px 26px 45px;
      align-items: center;
    }
    a {
      height: 25px;
      line-height: 25px;
      padding: 0;
      background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
      color: white;
    }
    :hover {
      background-image: url(${CardBg});
      background-position: 0 0;
      a {
        box-shadow: none;
      }
    }
    .more-button {
      width: 90px;
      border: none;
      margin-top: 0;
    }
    .recharge-button {
      width: 90px;
      margin-top: 5px;
      border: none;
    }
  }
`
export const CouponTitle = styled(Flex)`
  position: relative;
`
export const CouponNumber = styled.p`
  font-size: ${(p) => (p.firstText === 'first' ? '42px' : '48px')};
  font-weight: bold;
  color: ${color.primary};
  margin: 0;
  margin-top: ${(p) => (p.firstText === 'first' ? '10px' : '0')};
  &.more {
    font-size: 30px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 28px;
    line-height: 29px;
    &.more {
      font-size: 18px;
    }
  }
`
export const CouponUnit = styled(Flex)`
  position: ${(p) => (p.firstText === 'first' ? 'absolute' : '')};
  right: ${(p) => (p.firstText === 'first' ? '0px' : 'unset')};
  top: ${(p) => (p.firstText === 'first' ? '0' : 'unset')};
  flex-direction: ${(p) => (p.firstText === 'first' ? 'row' : 'column')};
  align-items: flex-start;
  color: white;
  margin-left: 10px;
  font-size: ${typography.textSmall};
  ${Media.lessThan(Media.small)} {
    font-size: 10px;
    margin-left: 2.13vw;
    top: ${(p) => (p.firstText === 'first' ? '-15px' : 'unset')};
  }
`
export const RechargeText = styled.p`
  margin-top: 0;
  color: #999999;
  font-size: ${typography.text};
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    margin: 0;
  }
`

export const CardPart = styled.div``

export const UnitPrice = styled.div`
  color: white;
  padding: 0 20px;
  ${Media.lessThan(Media.small)} {
    padding: 0;
    position: relative;
    text-align: center;
  }
`

export const Subscript = styled.div`
  background-image: url(${SubscriptImg});
  background-repeat: no-repeat;
  font-size: ${typography.textThin};
  text-align: right;
  background-position: right;
  padding-right: 8px;
  margin-bottom: -15px;
  ${Media.lessThan(Media.small)} {
    font-size: 8px;
    height: 17px;
    line-height: 17px;
    width: 49px;
    background-size: 100%;
    padding: 0;
    position: absolute;
    right: 2px;
    bottom: 36px;
    text-align: center;
  }
`

export const UnitPriceText = styled.div`
  span:first-child {
    font-size: 30px;
    font-weight: bold;
  }
  span:last-child {
    font-size: 16px;
    margin-left: 5px;
  }
  ${Media.lessThan(Media.small)} {
    span:first-child {
      font-size: 18px;
    }
    span:last-child {
      font-size: 10px;
      margin-left: 3px;
    }
  }
`
