import styled from 'styled-components'
import { color, typography } from '@renderbus/common/theme'
import Media from '@renderbus/common/theme/media'

export const CompareTableContainer = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 1200px;
  margin: 50px 0 70px;
  margin-left: 50%;
  transform: translateX(-50%);
  td,
  th {
    width: 25%;
    padding: 8px;
    padding-left: 46px;
    :not(:first-child) {
      text-align: center;
    }
    :nth-child(2) {
      color: ${color.primary};
    }
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: white;
    font-size: ${typography.h4};
  }
  tr {
    color: white;
    background-color: #111;
    transition: 0.3s;
    :nth-child(even) {
      background-color: ${color.background};
    }
    :hover {
      background-color: ${color.panel};
    }
  }
  thead tr {
    background-color: ${color.background};
  }
  ${Media.lessThan(Media.small)} {
    margin: 2.5rem 0 0;
    margin-left: 50%;
    td,
    th {
      padding-left: 8px;
    }
  }
`
