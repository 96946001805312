import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@renderbus/common/components'
import {
  Card,
  CouponTitle,
  CouponNumber,
  CouponUnit,
  RechargeText,
  CardPart,
  UnitPrice,
  Subscript,
  UnitPriceText,
} from './atom'

class RechargeCard extends React.PureComponent {
  render() {
    let {
      recharge,
      coupon,
      type = 'GPU',
      disabled = false,
      classProp,
      unit,
      getRechargeEvent,
    } = this.props
    return (
      <Card className={classProp}>
        {classProp === 'last' ? (
          <>
            <CardPart>
              <CouponTitle>
                <CouponNumber className='more'>{coupon}</CouponNumber>
              </CouponTitle>
              <RechargeText>
                更多特殊要求
                <br />
                更多价格优惠
              </RechargeText>
            </CardPart>
            <CardPart>
              <Button
                as='a'
                href='https://wpa1.qq.com/ujoglPGq?_type=wpa&qidian=true'
                className='more-button'
              >
                联系我们
              </Button>
            </CardPart>
          </>
        ) : (
          <>
            <CardPart>
              <CouponTitle>
                <CouponNumber firstText={classProp}>{coupon}</CouponNumber>
                <CouponUnit firstText={classProp}>
                  <span> {type}</span>
                  <span>青云券</span>
                </CouponUnit>
              </CouponTitle>
              <RechargeText>充值{recharge}元可得</RechargeText>
            </CardPart>
            <CardPart>
              <UnitPrice>
                <Subscript>折后价</Subscript>
                <UnitPriceText>
                  <span>{unit}</span>
                  <span>元/机时</span>
                </UnitPriceText>
              </UnitPrice>
              <Button
                disabled={disabled}
                as='a'
                onClick={() => getRechargeEvent()}
                className='recharge-button'
              >
                {disabled ? '已售罄' : '立即领取'}
              </Button>
            </CardPart>
          </>
        )}
      </Card>
    )
  }
}

RechargeCard.propTypes = {
  recharge: PropTypes.string.isRequired,
  coupon: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['GPU', 'CPU']),
}

export default RechargeCard
